#recaptcha-container {
  display: none;
}

@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-black-color;

  &[lang='ar'] {
    @include bodyFont-ar;
    button {
      @include bodyFont-ar;
    }
  }
  &[lang='en'] {
    @include bodyFont-ar;
    button {
      @include bodyFont-ar;
    }
  }
  // &[lang="en"] {
  // 	@include bodyFont-en;
  // 	button {
  // 		@include bodyFont-en;
  // 		font-weight: bold;
  // 	}
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  // background-color: #fff;
  @include custom-scrollbar;
}

.app {
  min-height: 100vh;
  height: 100%;
  // background-color: #f5f5f5;
  background-color: #f7f8fa;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  &.app-rtl {
    @include bodyFont-ar;
  }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}
a:hover {
  color: $main-app-color;
}
.page-header {
  text-align: center;
  .main-title {
    font-size: 35px;
    font-family: 'Cairo-Bold';
    color: #000;
  }
  .page_link {
    b {
      margin: 0px 10px;
    }
    .home {
      color: $secondary-app-color;
      font-family: 'Cairo-Regular';

      font-size: 18px;
    }
    .page {
      color: $main-app-color;
      font-family: 'Cairo-Regular';

      font-size: 18px;
    }
  }
}
.hero-section {
  .slick-dots {
    bottom: 38%;
  }

  .slick-dots li button:before {
    opacity: 0.84;
    background-color: rgba(0, 0, 0, 0.84);
    font-size: 12px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    width: 30px;
    background-color: #fff;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li {
    width: 30px;
    text-align: center;
    justify-content: center;
  }
}

.multi-items {
  .slick-dots li button:before {
    opacity: 0.84;
    background-color: #643a7163;
    font-size: 12px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    width: 30px;
    background-color: $main-app-color;
    height: 10px;
    border-radius: 10px;
  }
  .slick-list {
    z-index: 2;
  }
  .slick-dots {
    text-align: initial;
    top: -100px;
    z-index: 1;
    li {
      width: 30px;
      text-align: center;
      justify-content: center;
    }
  }
}

.ant-picker-dropdown {
  z-index: 10500;
  width: 283px;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  outline: none;
  border-color: #f7f7f7;
  box-shadow: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter {
  width: fit-content;
  min-width: 200px !important;
  left: 659px;
  top: 164px;
  &:hover {
    cursor: pointer;
  }
}

.ant-dropdown-arrow {
  display: none;
}
.ant-dropdown-menu {
  border-radius: 12px;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #fff0;
}

.ant-dropdown {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    text-align: start;
  }
  .item-list {
    color: #b3b8bd;
    font-size: 16px;
    font-family: 'Cairo-SemiBold';
    display: grid;
    grid-template-columns: 50px auto;
    &:hover {
      color: rgba(0, 0, 0, 0.84);
    }
    img {
      margin: 0px 10px;
    }
  }
  .dd {
    width: 14px;
    height: 14px;
    justify-content: center;
    display: grid;
    align-items: center;
    margin: auto;
  }
  .box {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: 1px solid #b3b8bd;
    justify-content: center;
    display: grid;
    align-items: center;
    margin: auto;
    img.active {
      display: inline;
    }
    img.notActive {
      display: none;
    }
  }
}
// .ant-switch-handle:before {
//   background-color: $main-app-color;
// }
.ant-switch {
  background-color: #fff;
  .ant-switch-handle:before {
    background-color: #b3b8bd;
  }
}
.ant-switch-checked {
  background-color: #fff;
  .ant-switch-handle:before {
    background-color: $main-app-color;
  }
}

.modal-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #f1f1f3;
  padding-bottom: 10px;
  .modal-name {
    text-align: start;
    align-items: center;
    display: grid;
    .name {
      text-align: initial;
      color: #000;
      font-family: 'Cairo-Bold';
      font-size: 20px;
    }
  }
  .close {
    text-align: end;

    button {
      background-color: #fff;
      border: none;
      font-family: 'Cairo-SemiBold';
      font-size: 16px;
      color: $main-app-color;
      width: 50px;
      height: 50px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.upload-files-wrapper {
  .file-input-label {
    color: #666;
    margin-bottom: 8px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .dropzone-itself {
    min-height: 132px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    color: #bdbdbd;
    cursor: pointer;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
}
.imgs-preview-wrapper {
  display: flex;
  flex-wrap: wrap;

  .img-preview {
    display: grid;
    place-items: center;
    img {
      width: 200px;
    }
  }
}
.ant-tabs-tab {
  text-align: initial;
  width: fit-content;
  padding: 10px;
}
.ant-tabs-tab-btn {
  color: #b3b8bd;
  font-family: 'Cairo-SemiBold';
  font-size: 20px;
  text-align: initial;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $main-app-color;
}

.ant-tabs-ink-bar {
  background: $main-app-color;
  height: 3px !important;
}

.ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  width: 200px;
}

.setting-menu {
  padding: 20px 0px;
  .item-list {
    text-align: center;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
}

.ant-form-item-label {
  text-align: start !important;
  .ant-form-item-no-colon {
    color: $main-app-color !important;
    text-align: start;
    font-family: 'Cairo-SemiBold';
  }
}

.select-option-label {
  color: $main-app-color !important;
  font-family: 'Cairo-SemiBold';
}

.ant-table-pagination-right {
  justify-content: center;
  direction: ltr;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  left: 0;
}

.MuiBox-root {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d500;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5f8b9e8f;
    background-color: #b5b5b5;
    border-radius: 10px;

    &:hover {
      background: #aaa;
    }
  }
}
