@import '../../scss/config';

.home-page {
  background-color: #f7f8fa;
  p {
    font-size: xx-large;
    color: $main-app-color;
  }
  button {
    background-color: $main-app-color;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    &:hover {
      cursor: pointer;
    }
    a {
      color: #fff;
    }
  }
}
