@import '../../scss/config';

.item-page {
  .tab-items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 32px;

    div {
      padding: 20px;
      color: #fff;
      //   background-color: rgba($color: #000000, $alpha: 1.0);
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: $main-app-color;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.area-continer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 20px;
  padding: 32px;

  .card-data {
    display: grid;
    grid-template-columns: 1fr;

    // grid-gap: 32px;
    div {
      display: grid;
      align-items: center;
    }
  }

  .card-div {
    background-color: $main-app-color;
    padding: 20px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 150px;
      height: 150px;
    }

    div {
      color: #fff;
    }
  }
}

.forget-page {
  background-color: #ebebeb;
  border-radius: 12px;
  .forget-header {
    display: grid;
    grid-template-columns: 1fr 20%;

    .close {
      text-align: end;
      justify-content: end;
      display: grid;

      button {
        background-color: #fff0;
        display: grid;
        justify-content: center;
        align-items: center;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .pice-data-desc {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 5px;
    .info-frist {
      //   background-color: adjust-hue($color: #21406e, $degrees: 60%);
      // background-color: rgba($color: #21406e, $alpha: 0.6);

      height: 73.31px;
      color: #21406e;
      justify-content: center;
      display: grid;
      align-items: center;
      font-weight: bolder;
    }
    .info-title {
      // background-color: #21406e;
      color: #21406e;
      justify-content: center;
      display: grid;
      align-items: center;
      font-weight: bolder;

      height: 73.31px;
    }
    .info-data {
      // background-color: #21406e;
      color: #21406e;
      justify-content: center;
      display: grid;
      align-items: center;
      font-weight: bolder;

      height: 73.31px;
    }
  }
}

.forget-password {
  background-color: #68b88b;
  margin-top: 10px;
  padding: 10px;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  color: #fff;
}

.two {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 12px;
  .edite {
    margin: auto;
    background-color: $main-app-color;
    color: #fff;
    border-radius: 12px;
    height: 49px;
    text-align: center;
    align-items: center;
    padding-top: 11px;
    &:hover {
      cursor: pointer;
    }
  }
}
