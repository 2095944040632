@import '../../scss/config';

.login-form {
  width: 100%;
  display: grid;
  height: max-content;
  border: 0px solid #fff;
  max-width: 452px;
  border-radius: 10px;
  background: #fff;

  h2 {
    margin: 22px 0;
    color: #777;
  }

  .password {
    text-align: end;
    div {
      background-color: #fff0;
      font-size: 16px;
      font-family: 'Cairo-SemiBold';
      color: $secondary-app-color;
      border: none;
      height: 72%;
      margin: auto;
      display: grid;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
  // box-shadow: 0 0 16px rgba(41, 42, 51, 0.06), 0 6px 20px rgba(41, 42, 51, 0.02);
  .ant-form-item-explain-error {
    text-align: start;
    color: red;
  }

  .forget-password {
    // background-color: #fff0;
    // color: $secondary-app-color;
    font-size: 13px;
    font-family: 'Cairo-SemiBold';
    // border: none;
    &:hover {
      cursor: pointer;
    }
    a {
      color: $secondary-app-color;
      font-size: 13px;
      font-family: 'Cairo-SemiBold';
    }
  }

  .ant-checkbox-wrapper {
    span {
      color: $secondary-app-color;
      font-size: 13px;
      font-family: 'Cairo-SemiBold';
    }
  }

  .MuiInput-root {
    outline: none;
    height: 62px;
    border: 1px solid #f7f8fa !important;
    border-radius: 12px;
    background-color: #f7f8fa;
    input {
      height: 100%;
      padding: 10px;
      font-family: 'Cairo-SemiBold';
    }

    &::before {
      border: none !important;
    }

    &::after {
      border: none;
    }
  }

  .ant-form-item-control {
    margin: 0px;
    text-align: start;

    div {
      text-align: start;
    }
  }

  .form-body {
    padding: 32px;
    text-align: center;
    display: grid;
    grid-gap: 12px;

    .ant-checkbox-wrapper {
      color: #777;
      font-size: 16px;
    }

    .btn {
      // justify-content: center;
      display: grid;
    }
    .submit-btn {
      margin-top: 12px;
      margin: auto;
      cursor: pointer;
      background-color: $main-app-color !important;
      outline: 0;
      border: 0;
      color: #fff;
      padding: 18px 18px;
      border-radius: 5px;
      display: flex;
      // width: 40%;
      min-width: 50%;
      justify-content: center;
      height: max-content;
      align-items: center;
      border-radius: 12px;
      text-align: center;
    }
  }
}
