.info-form {
  max-width: 666px !important;
  margin: 42px auto !important;
  .input-price-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 12px;

    .price-wrap {
      display: grid;
      place-items: center;
      gap: 2px;
      .value {
        background-color: #68b88b;
        color: #fff;
        padding: 4px 22px;
        border-radius: 8px;
        font-size: 20px;
      }
    }
  }

  .save-end-btns {
    border-top: 1px solid rgba(#000, 0.2);
    padding-top: 22px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 12px;
    align-items: center;

    button {
      margin: 0 !important;
      width: fit-content !important;
      padding: 14px 52px !important;
      min-width: 170px !important;
      background-color: #68b88b !important;
      &.end-auction-btn {
        background-color: rgb(147, 147, 147) !important;
      }
    }
  }
}
