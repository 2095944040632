@import '../../scss/config';

.setting {
  padding: 20px 10px;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-family: 'Cairo-Bold';
}
.app-layout {
  display: grid;
  // grid-template-columns: 273px auto;
}
.header-home {
  // background: #22406d;
  background-color: #fff;
  padding: 20px 0px;
  display: grid;
  grid-template-columns: auto 200px;
  margin: auto;
  div {
    display: grid;
    align-items: center;
  }
  hr {
    width: 100%;
    background-color: rgba(#000, 0.1);
  }
  img {
    // width: 99px;
    height: 104px;
  }
  p {
    // color: #fff;
    color: $main-app-color;
    border: none;

    width: 400px;
  }
  button {
    width: fit-content;
    background-color: #0000;
    color: #fff;
    border: none;

    width: 400px;
    a {
      color: #fff;
      border: none;
    }
  }
  .signout-edit-btns {
    display: flex;
    gap: 22px;
    align-items: center;
    .signout-btn,
    .edit-profile-btn {
      background-color: #68b88b;
      color: #fff;
      padding: 12px 28px;
      border-radius: 5px;
      border: 0;
      outline: 0;
      width: fit-content;
      cursor: pointer;
    }

    .signout-btn {
      background-color: rgb(255, 101, 101);
    }
  }
}
