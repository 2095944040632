@import '../../scss/config';

.settings-page {
  padding: 50px 0px;
  .page-title {
    padding: 25px 0px;
    p {
      text-align: start;
      color: #000;
      font-family: 'Cairo-Bold';
      font-size: 24px;
    }
  }
  .settings-items {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.info-tab {
  display: grid;
  grid-template-columns: auto;
  .login-form {
    max-width: 81%;
    background-color: #fff0;
  }
  .social {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    display: grid;
  }
  .form-body {
    &.form-grid {
      display: grid;
      grid-template-columns: 70% 30%;
      grid-gap: 32px;
    }
  }
  textarea.ant-input {
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 10px;
    border: none;
  }
  .title {
    font-size: 20px;
    font-family: 'Cairo-SemiBold';
    color: #000;
    margin-bottom: 10px;
    text-align: start;
  }
  .img {
    display: grid;
    align-items: end;
    img {
      width: 100%;
    }
  }
}

