@import '../../scss/config';

.login-page {
  min-height: 100vh;
  padding: 32px 0;
  background-color: #f7f8fa;
  display: grid;
  & > .custom-container {
    display: grid;
    place-items: center;
    align-content: center;
  }
  .ant-form {
    margin: auto;
  }
  .login-header {
    display: grid;
    // grid-template-columns: 1fr 20%;

    .close {
      text-align: end;
      justify-content: end;
      display: grid;

      button {
        background-color: #fff0;
        display: grid;
        justify-content: center;
        align-items: center;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .modal-name {
      text-align: center;

      .name {
        text-align: center;
        color: #b3b8bd;
        margin: 20px 0px;
        font-family: 'Cairo-SemiBold';
        font-size: 20px;
      }

      .opation {
        text-align: initial;
        font-family: 'Cairo-SemiBold';
        font-size: 16px;
        color: $secondary-app-color;

        button {
          background-color: #fff;
          border: none;
          font-family: 'Cairo-SemiBold';
          font-size: 16px;
          color: $main-app-color;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.page-logo {
  display: block;
  margin-bottom: 32px;
  img {
    height: 132px;
  }
}
