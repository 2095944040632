.countdown-page {
  min-height: 70vh;
  padding: 32px 0;
  background-color: #f7f8fa;
  display: grid;
  place-items: center;
  .minutes-form {
    background-color: #fff;
    padding: 18px 32px;
  }
}
